import { Injectable } from "@angular/core";
import { filter, map } from "rxjs/operators";
import { AppConfigService } from "./app-config.service";

@Injectable({ providedIn: "root" })
export class WaitForStartupModel  {

    constructor(
        private appConfigService: AppConfigService,
    ) {}

    canActivate() {

        return this.appConfigService.startupModelLoadingStatus$
            .pipe(
                map(status => status === "LOADED"),
                filter(isLoaded => isLoaded),
            );
    }
}
