import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { RouterStateSnapshot } from "@angular/router";

@Injectable({ providedIn: "root" })
export class SetPageClass  {

    constructor(
        @Inject(DOCUMENT) private document: Document,
    ) {}

    canActivate(_, state: RouterStateSnapshot) {

        const firstRoutePart = /^\/?(\w+)/.exec(state.url)[1];

        const newClassList = [
            ...this.document.documentElement
                .className
                .split(" ")
                .filter(i => !!i && i.indexOf("page--") == -1),
            `page--${firstRoutePart}`,
        ];

        this.document.documentElement.className = newClassList.join(" ");

        return true;
    }
}
