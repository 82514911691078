import { RequestExtraConfig } from "@Portal/RequestExtraConfig";
import { HTTP_INTERCEPTORS, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpEvent } from "@angular/common/http";
import { ExistingProvider, Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { filter, first, share, takeUntil } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class RequestScopeInterceptor implements HttpInterceptor {

    private requestScopeStream = new Subject<string>();

    intercept(request: HttpRequest<any>, next: HttpHandler) {

        const requestParams: HttpParams & { interceptorConfig?: RequestExtraConfig } = request.params;
        const requestScope = requestParams.interceptorConfig?.requestScope;

        if (!requestScope) return next.handle(request);

        // Notify active requests in the scope about new request
        this.requestScopeStream.next(requestScope);

        const httpEventStream = new Subject<HttpEvent<any>>();
        let requestCanceled = false;

        const newerHttpRequestSent = this.requestScopeStream
            .pipe(
                filter(i => i === requestScope),
                first(),
                share(),
            );

        newerHttpRequestSent.subscribe(() => { requestCanceled = true; });

        next.handle(request).pipe(
            takeUntil(newerHttpRequestSent),
        )
        .subscribe({
            next: event => { httpEventStream.next(event); },
            error: error => { httpEventStream.error(error); },
            complete: () => {

                // if we complete canceled request, this will allow source function to continue their execution
                // which isn't something we really want
                // possible memory leak here, but it's not a big deal
                if (!requestCanceled) httpEventStream.complete();
            },
        });

        return httpEventStream;
    }
}

export const RequestScopeInterceptorProvider: ExistingProvider = {
    provide: HTTP_INTERCEPTORS,
    useExisting: RequestScopeInterceptor,
    multi: true,
};
