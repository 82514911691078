import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AppConfigService } from "./app-config.service";

@Injectable({ providedIn: "root" })
export class KickstartStartupModel  {

    constructor(
        private router: Router,
        private appConfigService: AppConfigService,
    ) {}

    canLoad() {

        if (!this.appConfigService.startupModelLoaded && !this.appConfigService.startupModelLoading) {

            this.appConfigService.loadStartupModelAsync(this.router.getCurrentNavigation().extractedUrl.toString());
        }

        return true;
    }
}
